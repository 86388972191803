import React from "react";
import Content from "../components/Common/Content";
import Layout from "../components/Common/Layout";
import Main from "../components/Common/Main";
import Section from "../components/Common/Section";
import Title from "../components/Common/Title";
import Spacing from "../constants/Spacing";
import Paragraph from "../components/Common/Paragrpah";
import {
    developerModules,
    devopsModules,
} from "../data/learningPathData";
import Module from "../components/LearningPath/Module";
import Color from "../constants/Color";

const LearningPath = () => {
    return (
        <Layout title="Learning Path">
            <Main>
                <Section>
                    <Content>
                        <Title
                            marginTop={Spacing.SPACING_3}
                            marginBottom={Spacing.SPACING_3}
                        >
                            Learning Path
                        </Title>
                        <Paragraph marginBottom={Spacing.SPACING_4}>
                            Content is broken down into modules. Each module
                            consists of a set of lessons. The module covers a
                            single topic and you don't have to take the modules
                            in any specific order.
                        </Paragraph>
                    </Content>
                </Section>
                <Section backgroundColor={Color.BACKGROUND_LIGHT_2}>
                    <Content>
                        <Title
                            marginBottom={Spacing.SPACING_3}
                            marginTop={Spacing.SPACING_4}
                        >
                            Developer Modules
                        </Title>
                        {developerModules.map(({ subtitle, text }, index) => {
                            return (
                                <Module
                                    key={index}
                                    subtitle={subtitle}
                                    text={text}
                                />
                            );
                        })}
                    </Content>
                </Section>
                <Section>
                    <Content>
                        <Title
                            marginBottom={Spacing.SPACING_3}
                            marginTop={Spacing.SPACING_4}
                        >
                            DevOps Modules
                        </Title>
                        {devopsModules.map(({ subtitle, text }, index) => {
                            return (
                                <Module
                                    key={index}
                                    subtitle={subtitle}
                                    text={text}
                                />
                            );
                        })}
                    </Content>
                </Section>
                <Section backgroundColor={Color.BACKGROUND_LIGHT_2}>
                    <Content>
                        <Title
                            marginBottom={Spacing.SPACING_3}
                            marginTop={Spacing.SPACING_4}
                        >
                            Command Walkthroughs
                        </Title>
                        <Paragraph marginBottom={Spacing.SPACING_4}>
                            Some commands are so useful and have so many options
                            that they deserve a section of their own. You will
                            use these for sure in your scripts and daily tasks.
                            Here you will learn many tips and tricks with those
                            commands.
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            grep
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            curl
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            awk
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            apt
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            ip
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_4}>
                            tar
                        </Paragraph>
                    </Content>
                </Section>
                <Section>
                    <Content>
                        <Title
                            marginBottom={Spacing.SPACING_3}
                            marginTop={Spacing.SPACING_4}
                        >
                            Cheat Sheets and Diagrams
                        </Title>
                        <Paragraph marginBottom={Spacing.SPACING_4}>
                            Quick reminders for useful flags and principles that
                            are hard to remember. You can save these to your
                            computer or even print the images and keep it on
                            your desk.
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Navigation shortcuts
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            File permissions
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Performance overview
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            File system hierarchy
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Vim keybindings
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Cron
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Tar — Gzip
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Bash helpsheet
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Kernel overview
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Netcat
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            IPtables
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Configuration files
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_4}>
                            Signals
                        </Paragraph>
                    </Content>
                </Section>
                <Section backgroundColor={Color.BACKGROUND_LIGHT_2}>
                    <Content>
                        <Title
                            marginBottom={Spacing.SPACING_3}
                            marginTop={Spacing.SPACING_4}
                        >
                            Extra Materials
                        </Title>
                        <Paragraph marginBottom={Spacing.SPACING_4}>
                            Here are some interesting articles that will help
                            you expand your knowledge and understanding of
                            Linux. These are not strictly related to the command
                            line interface itself but you might find these
                            topics both useful and fascinating while on the path
                            to mastering the command line.
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Terminal history
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Choosing Linux distribution
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Installing Linux
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Bash workbook
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Text editors
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Boot sequence
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Systemd
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            System calls
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_2}>
                            Bash pitfalls
                        </Paragraph>
                        <Paragraph marginBottom={Spacing.SPACING_7}>
                            Check server health
                        </Paragraph>
                    </Content>
                </Section>
            </Main>
        </Layout>
    );
};

export default LearningPath;
