export const developerModules = [
    {
        subtitle: "Introduction",
        text: `
            In this module, you will learn what a terminal is and how to
            interact with it. Shortcuts such as tab completion, and keyboard
            shortcuts to move the cursor around and manipulate input and
            commands history. You will also try out some basic everyday
            commands and arguments.
        `,
    },
    {
        subtitle: "Common commands",
        text: `
            Some of the most common stuff you will do daily. Creating
            files and moving those around, checking the size, searching
            for files based on name or content, and much more.
        `,
    },
    {
        subtitle: "Bash tricks",
        text: `
            Here we take a look at the true power of Unix command line and
            that is combining multiple simple programs to get some result.
            You will learn how to pipe the output of a single command to
            another command or redirect it to a file. We'll also use variables,
            aliases, check for command results, and other interesting stuff.
        `,
    },
    {
        subtitle: "Text editing",
        text: `
            Unix comes with incredibly powerful command line based text
            editors such as emacs, nano, and vi. You will learn how to do
            basic text editing in those and some neat tips & tricks.
        `,
    },
    {
        subtitle: "Users and permissions",
        text: `
            Unix is a multi-user operating system. Every file and folder on
            Unix has an owner. You learn how to check for users, create and
            delete users and groups, and manage file permissions.
        `,
    },
    {
        subtitle: "Software packages",
        text: `
            Software installations on Unix are done using package managers.
            Here you will learn how to list and install new packages, upgrade
            those already installed, and delete the ones you no longer need.
        `,
    },
    {
        subtitle: "Shell scripting",
        text: `
            You can package any task you perform in the command line interface
            into a reusable script. Learn script building blocks like variables,
            conditionals, loops, inputs, outputs, command substitution, brace
            expansion, functions, and arrays.
        `,
    },
    {
        subtitle: "SSH",
        text: `
            SSH is a secure way to access a computer over an unsecured network.
            It provides strong password authentication and public key authentication.
            It is used to manage systems and applications remotely. You will learn
            how to connect to other machines and how to configure your machine for
            safe SSH connections.
        `,
    },

];

export const devopsModules = [
    {
        subtitle: "Filesystem hierarchy",
        text: `
            Unix has a unique filesystem structure which you may find very
            unusual if you come from Windows. You will learn about Filesystem
            Hierarchy Standard (FHS) and check out where you can find logs,
            configuration files, essential binaries, and libraries. You will
            also learn how to work with temporary files.
        `,
    },
    {
        subtitle: "Cron",
        text: `
            The crond daemon enables you to run programs at regular intervals.
            Much better than staying up late and running the programs manually.
            You will learn ins and outs of cron in this module.
        `,
    },
    {
        subtitle: "Systemd",
        text: `
            Systemd is an init system used to get your system up and running.
            You will learn how to start and stop processes, check logs, mount
            filesystems, enable programs to start when your system starts up,
            and other good stuff.
        `,
    },
    {
        subtitle: "Resource management",
        text: `
            Memory, CPU, and disk space are some of the essential resources
            an operating system manages. Learn resource monitoring with top,
            iostat, lsof, and other tools. By completing this module you will
            understand the resource utilization of your system and have the
            ability to identify resource consumption by processes.
        `,
    },
    {
        subtitle: "Filesystems",
        text: `
            There are multiple filesystem types used for different purposes.
            You will learn the differences between filesystems and concepts
            like inodes, mounts, and mount options. You will also create and
            mount temporary and memory-backed filesystems.
        `,
    },
    {
        subtitle: "Networking",
        text: `
            Learn how to configure networking on your system. You will learn
            about different kinds of network interfaces, how to configure network
            interfaces, how to set up custom hosts entries, change DNS resolver
            settings, and other networking-related operations.
        `,
    },
    {
        subtitle: "Firewall",
        text: `
            Linux comes with a very powerful kernel-based firewall called iptables.
            You will learn to allow, block or modify certain types of traffic which
            are essential skills to make your servers safe on the public internet.
        `,
    },
    {
        subtitle: "System calls",
        text: `
            System calls are a mechanism that programs use to interact with the
            kernel. Whenever a program wants to open and write to a file or
            perform any other operation with a kernel it makes a system call.
            You will learn how to track system calls in this module. It is a
            very useful troubleshooting technique.
        `,
    },
    {
        subtitle: "Network troubleshooting",
        text: `
            When something does not work on the network level you need to take
            a deep dive under the hood to figure out what is going on. In this
            module, you will learn how to track network packets, scan for open
            ports, test connectivity to remote machines using ping and telnet,
            and many more useful techniques that will be a valuable addition to
            your troubleshooting skills toolbox.
        `,
    },
    {
        subtitle: "Namespaces and cgroups",
        text: `
            These are the basic building blocks of modern container engines such
            as Docker. You will learn what namespaces are, and how to create a
            new or run a program in an existing namespace. You will also learn
            how to allocate resources such as CPU time, system memory, and network
            bandwidth to a process or a group of processes using cgroups.
        `,
    },
];
