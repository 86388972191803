import React, { Fragment } from "react";
import styled from "styled-components";
import Spacing from "../../constants/Spacing";
import Paragraph from "../Common/Paragrpah";
import Subtitle from "../Common/Subtitle";

interface Props {
    subtitle: string;
    text: string;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: ${Spacing.SPACING_3};

    &:nth-child(n-1) {
        margin-bottom: ${Spacing.SPACING_4};
    }
`;

const Module = ({ subtitle, text }: Props) => {
    return (
        <Container>
            <Subtitle marginBottom={Spacing.SPACING_2}>{subtitle}</Subtitle>
            <Paragraph>{text}</Paragraph>
        </Container>
    );
};

export default Module;
